import React from "react"
import Grid from "../../Atoms/Grid"
import RealizationElement from "../../Atoms/RealizationElement"
import realization1 from '../../../assets/images/realizations/bialystok1/bialystok-mini.jpg'
import realization2 from '../../../assets/images/realizations/bialystok2/bialystok2-mini.jpg'
import realization3 from '../../../assets/images/realizations/mariampol/mariampol-mini.jpg'
import realization4 from '../../../assets/images/realizations/czyzew/czyzew-mini.jpg'
import realization5 from '../../../assets/images/realizations/ustka/ustka-mini.jpg'
import realization6 from '../../../assets/images/realizations/zambrow/zambrow-mini.jpg'
import realization7 from '../../../assets/images/realizations/gizycko/gizycko-mini.jpg'
import realization8 from '../../../assets/images/realizations/lomza/lomza-mini.jpg'
import realization9 from '../../../assets/images/realizations/preili/preili-mini.jpg'
import styled from "styled-components"
import { localize } from "../../Atoms/Language"

const StyledGrid = styled(Grid)`
  @media screen and (max-width: 900px) {
    flex-direction: column;
    align-items: center;
    margin-top: 40px;
  }
`

const StyledRealizationElement = styled(RealizationElement)`
  margin-bottom: 35px;
`

const OurProjects = ({language}) => (
  <section className="mt16 mb16">
    <div className="container">
      <h2 className="header2">{localize("287")}</h2>
      <StyledGrid>
        <StyledRealizationElement language={language} url="/realizacje/bialystok-1" image={realization1} name={localize("183")} type={localize("186")} />
        <StyledRealizationElement language={language} url="/realizacje/bialystok-2" image={realization2} name={localize("184")} type={localize("187")} />
        <StyledRealizationElement language={language} url="/realizacje/mariampol" image={realization3} name={localize("185")} type={localize("187")} />
        <StyledRealizationElement language={language} url="/realizacje/czyzew" image={realization4} name={localize("288")} type={localize("294")} />
        <StyledRealizationElement language={language} url="/realizacje/ustka" image={realization5} name={localize("289")} type={localize("187")} />
        <StyledRealizationElement language={language} url="/realizacje/zambrow" image={realization6} name={localize("290")} type={localize("186")} />
        <StyledRealizationElement language={language} url="/realizacje/gizycko" image={realization7} name={localize("291")} type={localize("295")} />
        <StyledRealizationElement language={language} url="/realizacje/lomza" image={realization8} name={localize("292")} type={localize("296")} />
        <StyledRealizationElement language={language} url="/realizacje/preili" image={realization9} name={localize("293")} type={localize("296")} />
      </StyledGrid>
    </div>
  </section>
)

export default OurProjects
