import React from "react"
import styled from "styled-components"
import ImageWithText from "../../Molecules/ImageWithText/ImageWithText"
import { graphql, useStaticQuery } from "gatsby"
import { localize } from "../../Atoms/Language"

const imageQuery = graphql`
  {
    file(name: { eq: "about_us2" }) {
      childImageSharp {
        fixed(height: 400){
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`

const StyledWrapper = styled(ImageWithText)`

`

const TheHighestQuality = () => {
  const data = useStaticQuery(imageQuery)

  return (
    <StyledWrapper fixed={data.file.childImageSharp.fixed} imageAltText={localize("348")} isRevert>
      <h2 className="header3">{localize("282")}</h2>
      <p className="body">{localize("283")}</p>
      <p className="body">{localize("284")}</p>
      <p className="body">{localize("285")}</p>
      <p className="body">{localize("286")}</p>
    </StyledWrapper>
  )
}
export default TheHighestQuality
