import React from "react"
import ImageWithText from "../../Molecules/ImageWithText/ImageWithText"
import { graphql, useStaticQuery } from "gatsby"
import { localize } from "../../Atoms/Language"

const imageQuery = graphql`
  {
    file(name: { eq: "about_us1" }) {
      childImageSharp {
        fixed(height: 400){
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`

const AboutUsContent = () => {

  const data = useStaticQuery(imageQuery)

  return (
    <>
      <ImageWithText className="mt8 mb8" fixed={data.file.childImageSharp.fixed} imageAltText={localize("347")}>
        <h1 className="header2">{localize("1")}</h1>
        <p className="body">{localize("279")}</p>
        <p className="body">{localize("280")}</p>
        <p className="body">{localize("281")}</p>
      </ImageWithText>
    </>
  )
}

export default AboutUsContent
