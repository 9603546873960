import React from "react"
import AboutAsContent from "../components/Organisms/StaticSections/AboutUs"
import TheHighestQuality from "../components/Organisms/StaticSections/TheHighestQuality"
import OurProjects from "../components/Organisms/OurProjects/OurProjects"
import CTABanner from "../components/Organisms/CTABanner/CTABanner"
import { WithIntl } from "../components/Atoms/withTranslate"
import SEO from "../components/seo"
import { localize } from "../components/Atoms/Language"

const AboutAs = ({ language }) => (
  <>
    <SEO
      lang={language}
      title={localize("277")}
      description={localize("278")}
    />
    <AboutAsContent/>
    <TheHighestQuality/>
    <div id="realizacje">
      <OurProjects language={language}/>
    </div>
    <CTABanner language={language}/>
  </>
)

export default WithIntl()(AboutAs)
